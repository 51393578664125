exports.onClientEntry = () => {
  const { search } = window.location
  const query = search.replace('?', '').trim()
  const adRef = {}
  const paramsStringSplitted = query.split('&')
  const params = paramsStringSplitted[0] === '' ? [] : paramsStringSplitted
  params.map(param => {
    const p = param.split('=')
    const key = p[0]
    const value = p.length > 0 ? p[1] : ''
    adRef[key] = value
    return param
  })
  if (window.sessionStorage && !!params.length) {
    const currentValue = window.sessionStorage.getItem('rFprAn')
    window.sessionStorage.setItem(
      'rFprAn',
      JSON.stringify({ ...JSON.parse(currentValue), ...adRef })
    )
  }
}

